import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTask, getTasks, createTasks } from '../../api/api';

const initialState: any = {
    selectedTask: null,
    tasks: null,
    myTasks: null,
    appliedTasks: null,
    isLoading: false,
    error: []
};

// getRoomList(accessToken);
export const fetchTask = createAsyncThunk('task/fetchTask', async (id: string | number) => {
    const task = await getTask(id);
    return { selectedTask : task };
});

export const fetchTasks = createAsyncThunk('task/fetchTasks', async (queryStr : string = '?order%5BcreatedAt%5D=desc') => {

    const tasks = await getTasks(queryStr);
    const tasksArray = tasks['hydra:member'];
    return { tasks : tasksArray };
});

export const fetchMyTasks = createAsyncThunk('task/fetchMyTasks', async (id: string) => {
    const queryStr = `?owner.id=${id}&ordercreatedAt=desc`;
    const tasks = await getTasks(queryStr);
    const tasksArray = tasks['hydra:member'];
    return { myTasks : tasksArray };
});

export const fetchAppliedTasks = createAsyncThunk('task/fetchAppliedTasks', async (id : string) => {
    const queryStr = `?requests.user.id=${id}`
    const tasks = await getTasks(queryStr);
    const tasksArray = tasks['hydra:member'];
    return { appliedTasks : tasksArray };
});

export const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTask.fulfilled, (state, action) => {
                state.selectedTask = action.payload.selectedTask;
            })
            .addCase(fetchTask.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(fetchTasks.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tasks = action.payload.tasks;
            })
            .addCase(fetchTasks.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(fetchTasks.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchMyTasks.fulfilled, (state, action) => {
                state.isLoading = false;
                state.myTasks = action.payload.myTasks;
            })
            .addCase(fetchMyTasks.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(fetchMyTasks.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAppliedTasks.fulfilled, (state, action) => {
                state.appliedTasks = action.payload.appliedTasks;
            })
            .addCase(fetchAppliedTasks.rejected, (state, action) => {
                state.error = action.payload;
            })
    },
});

export default taskSlice.reducer;
