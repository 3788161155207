import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLoginToken } from '../../api/api';

const initialState: any = {
    isLoggedIn: false,
    error: [],
    user: null,
};

export const fetchLoginToken = createAsyncThunk('user/fetchLoginToken', async (msAccessToken: string, { rejectWithValue }) => {
    try {
        const response = await getLoginToken(msAccessToken);

        if (response?.data) {
            localStorage.setItem("bmpUser", JSON.stringify(response.data));
        }

        return { user: response?.data };
    } catch (error: any) {
        return rejectWithValue(error);;
    }
});

export const logOut = createAsyncThunk("auth/logout", async () => {
    localStorage.removeItem("bmpUser");
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLoginToken.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.userProfile = action.payload.user;
            })
            .addCase(fetchLoginToken.rejected, (state, action) => {
                state.isLoggedIn = false;
                state.error.push(action.payload);
            })
            .addCase(logOut.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.userProfile = null;
            })
    },
});

export const { setAccessToken } = authSlice.actions;

export default authSlice.reducer;
